/* ==========================================================================
   Accueil
   ========================================================================== */

// .main-accueil {
//   min-height: 50vh;
// }

.bg-spe-accueil {
  /* Permalink - use to edit and share this gradient. Permalien - � utiliser pour �diter et partager ce gradient: https://colorzilla.com/gradient-editor/$brand-primary+0,ffffff+19,ffffff+28,ffffff+28,006344+28,006344+33,006344+33,d32a17+33,d32a17+33,d32a17+33,d32a17+100 */
  background: linear-gradient(135deg, $brand-primary 0%, $brand-primary 19%, $brand-primary 28%, $brand-primary 28%, $brand-second-chart 28%, $brand-second-chart 33%, $brand-second-chart 33%, $brand-second-chart 33%, $brand-second-chart 33%, $brand-second-chart 33%, $brand-second-chart 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.navbar-collapse{
  background-color: $brand-second-chart;
  @media(min-width: $screen-sm-min) {
    background-color: transparent;
  }
}

.bg-fond-encart {
  background: url(../images/charte/fond-encart.jpg) no-repeat center center;
  background-size: cover;
}

.bordure-metale {
  padding: 15px 30px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#4c4c4c+0,595959+12,666666+25,474747+39,2c2c2c+50,000000+51,111111+60,2b2b2b+76,1c1c1c+91,131313+100;Black+Gloss+%231 */
  background: linear-gradient(135deg, #4c4c4c 0%, #595959 12%, #666666 25%, #474747 39%, #2c2c2c 50%, #000000 51%, #111111 60%, #2b2b2b 76%, #1c1c1c 91%, #131313 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

/* ==========================================================================
Parallax
========================================================================== */

.parallax-window {
align-items: center;
min-height: 30vh;
overflow: hidden;
}


.parallax-window-accueil {
@extend .parallax-window;
display: flex;
flex-flow: row wrap;
justify-content: center;
text-align: center;
min-height: 90vh;
background: linear-gradient(to bottom, rgba(black, 0) 0%, rgba(black, .6) 100%);

>a {
   display: inline-block;
   position: relative;
   font-size: 4rem;
   text-decoration: none;
   text-transform: uppercase;
   font-weight: 700;
   color: white;

   >img {
      @extend .img-responsive;
      max-width: 90%;
      margin: 0 auto;
      transition: transform ease-out .3s;
   }

   >span {
      display: inline-block;
      transition: all ease-out .3s;
      text-shadow: .2rem .25rem .5rem rgba(black, .6);
   }

   @media(min-width: $screen-sm-min) {
      font-size: 4.5rem;

      &:hover {
         opacity: 1;

      >img {
         transform: scale(1.1);
      }

      >span {
         letter-spacing: .5rem;
         text-shadow: .4rem .5rem 1rem rgba(black, .2);
      }
      }
   }

   @media(min-width: $screen-md-min) {
      font-size: 5.5rem;
   }
}
}
